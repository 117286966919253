import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navLinks = [
    { text: t('mainPage'), path: '/' },
    { text: t('ourTeam'), path: '/team' },
    { text: t('publications'), path: '/publications' },
    { text: t('diseases'), path: '/diseases' },
    { text: t('about'), path: '/about' },
    { text: t('data'), path: '/data' },
    { text: t('download'), path: '/photos' },
    { text: t('live'), path: '/live' },
  ];

  return (
    <header className="relative border-b border-solid border-b-[#e8f5e9] px-4 md:px-10 py-3">
      <div className="flex items-center justify-between">
        {/* Logo ve üniversite ismi */}
        <div className="flex items-center gap-4 text-[#121714]">
          <Link to='/'>
            <div
              className="bg-center bg-no-repeat aspect-square bg-cover rounded-full size-10"
              style={{ backgroundImage: 'url("https://www.ogu.edu.tr/Content/2019/img/site-logo-outlined.png")' }}
            ></div>
          </Link>
          <Link to='/'>
            <h2 className="text-[#121714] text-sm md:text-lg font-bold leading-tight tracking-[-0.015em]">{t('university')}</h2>
          </Link>
        </div>

        {/* Dil değiştirme butonları - her zaman görünür */}
        <div className="flex items-center gap-2 md:ml-4">
          <button onClick={() => changeLanguage('en')} className="text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c]">
            EN
          </button>
          <button onClick={() => changeLanguage('tr')} className="text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c]">
            TR
          </button>
        </div>

        {/* Hamburger menü butonu - sadece mobilde görünür */}
        <button 
          className="md:hidden ml-4 p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className={`w-6 h-0.5 bg-[#121714] transition-all duration-300 ${isMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`}></div>
          <div className={`w-6 h-0.5 bg-[#121714] my-1.5 transition-all duration-300 ${isMenuOpen ? 'opacity-0' : ''}`}></div>
          <div className={`w-6 h-0.5 bg-[#121714] transition-all duration-300 ${isMenuOpen ? '-rotate-45 -translate-y-1.5' : ''}`}></div>
        </button>

        {/* Desktop menü */}
        <div className="hidden md:flex flex-1 justify-end items-center gap-4">
          {navLinks.map((link, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <div className="h-4 w-[1px] bg-[#36c26c]"></div>
              )}
              <Link 
                to={link.path} 
                className="relative text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c] after:content-[''] after:absolute after:left-0 after:bottom-[-2px] after:h-[2px] after:w-full after:scale-x-0 after:bg-[#36c26c] after:transition-transform after:duration-300 after:ease-in-out hover:after:scale-x-100"
              >
                {link.text}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Mobil menü */}
      <div 
        className={`absolute left-0 right-0 bg-white z-50 shadow-lg transition-all duration-300 ${
          isMenuOpen ? 'top-full opacity-100 visible' : 'top-[120%] opacity-0 invisible'
        } md:hidden`}
      >
        <div className="flex flex-col p-4">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              onClick={() => setIsMenuOpen(false)}
              className="py-3 px-4 text-[#121714] text-sm font-medium hover:bg-[#e8f5e9] hover:text-[#36c26c] rounded-lg transition-all duration-300"
            >
              {link.text}
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
}

export default Header;
