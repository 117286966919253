import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, Filter } from 'lucide-react';
import './spinner.css';

const ImageModal = ({ isOpen, onClose, imageUrl, date, formatDate }) => {
  if (!isOpen) return null;
  
  // Görüntünün ne kadar eski olduğunu hesaplayan fonksiyon
  const getImageAge = (date) => {
    const imageDate = new Date(date);
    const now = new Date();
    
    const diffInMinutes = Math.floor((now - imageDate) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    
    if (diffInDays > 0) {
      return `${diffInDays} gün ${diffInHours % 24} saat önce`;
    } else if (diffInHours > 0) {
      return `${diffInHours} saat ${diffInMinutes % 60} dakika önce`;
    } else {
      return `${diffInMinutes} dakika önce`;
    }
  };
  
  // Görüntünün yaşına bağlı renk belirleme
  const getAgeColor = (date) => {
    const imageDate = new Date(date);
    const now = new Date();
    const diffInHours = (now - imageDate) / (1000 * 60 * 60);
    
    if (diffInHours > 24) {
      return 'text-red-600';
    } else {
      return 'text-green-700';
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="relative max-w-4xl w-full bg-white rounded-lg shadow-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 z-10"
        >
          <X size={24} />
        </button>
        <div className="p-4">
          <img
            src={imageUrl}
            alt="Enlarged view"
            className="w-full h-auto rounded-lg"
          />
          {date && (
            <div className="mt-4 text-center">
              <p className="font-medium">
                {formatDate(date)}
              </p>
              <p className={`mt-1 font-medium ${getAgeColor(date)}`}>
                {getImageAge(date)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PhotoPage = () => {
  const [cameras, setCameras] = useState([]);
  const [cameraImages, setCameraImages] = useState({});
  const [modalImage, setModalImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uniqueCameraNames, setUniqueCameraNames] = useState([]);
  const [selectedCameraName, setSelectedCameraName] = useState('');
  // Varsayılan olarak eski görüntüleri gizlemek için true olarak ayarlandı
  const [hideOldImages, setHideOldImages] = useState(true);
  // Aktif kameraları takip etmek için yeni state
  const [activeCameras, setActiveCameras] = useState([]);

  const fetchCameras = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/cam/all');
      const camerasData = response.data;
      setCameras(camerasData);

      const imagesPromises = camerasData.map(async (camera) => {
        try {
          const imageResponse = await axios.get(`/camera/${camera.id}/latest?count=5`);
          const images = imageResponse.data;
          return { 
            cameraId: camera.id, 
            images: images,
            latestImageDate: images.length > 0 ? new Date(images[0].date) : null
          };
        } catch (error) {
          console.error(`Kamera ${camera.id} görüntüleri alınamadı:`, error);
          return { 
            cameraId: camera.id, 
            images: [],
            latestImageDate: null
          };
        }
      });

      const allImagesResults = await Promise.all(imagesPromises);
      
      // Create images object
      const imagesObject = allImagesResults.reduce((acc, curr) => {
        acc[curr.cameraId] = curr.images;
        return acc;
      }, {});
      setCameraImages(imagesObject);
      
      // Sort cameras by latest image date
      const sortedCameras = [...camerasData];
      sortedCameras.sort((a, b) => {
        const aResult = allImagesResults.find(result => result.cameraId === a.id);
        const bResult = allImagesResults.find(result => result.cameraId === b.id);
        
        const aDate = aResult?.latestImageDate || new Date(0);
        const bDate = bResult?.latestImageDate || new Date(0);
        
        return bDate - aDate; // Descending order (newest first)
      });
      
      setCameras(sortedCameras);
      
      // Son fotoğrafı 2 haftadan eski olmayan aktif kameraları belirle
      const activeCams = sortedCameras.filter(camera => {
        const cameraResult = allImagesResults.find(result => result.cameraId === camera.id);
        if (!cameraResult || !cameraResult.latestImageDate) return false;
        
        const now = new Date();
        const diffInDays = (now - cameraResult.latestImageDate) / (1000 * 60 * 60 * 24);
        return diffInDays <= 14 && cameraResult.images.length > 0;
      });
      
      setActiveCameras(activeCams);
      
      // Filtre için sadece aktif kamera isimlerini al
      const activeNames = [...new Set(activeCams.map(camera => camera.name))];
      setUniqueCameraNames(activeNames);
      
    } catch (error) {
      console.error('Kameralar alınamadı:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCameras();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const isImageOutdated = (date) => {
    const imageDate = new Date(date);
    const now = new Date();
    const diffInHours = (now - imageDate) / (1000 * 60 * 60);
    return diffInHours > 24;
  };

  const isImageTooOld = (date) => {
    const imageDate = new Date(date);
    const now = new Date();
    const diffInDays = (now - imageDate) / (1000 * 60 * 60 * 24);
    return diffInDays > 14; // 2 weeks
  };

  const handleImageClick = (image) => {
    setModalImage(image);
  };

  const handleFilterChange = (e) => {
    setSelectedCameraName(e.target.value);
  };

  // Görüntünün ne kadar eski olduğunu hesaplayan fonksiyon
  const getImageAge = (date) => {
    const imageDate = new Date(date);
    const now = new Date();
    
    const diffInMinutes = Math.floor((now - imageDate) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    
    if (diffInDays > 0) {
      return `${diffInDays} gün ${diffInHours % 24} saat önce`;
    } else if (diffInHours > 0) {
      return `${diffInHours} saat ${diffInMinutes % 60} dakika önce`;
    } else {
      return `${diffInMinutes} dakika önce`;
    }
  };

  const renderCameraSection = (camera) => {
    // Filter by camera name if selected
    if (selectedCameraName && camera.name !== selectedCameraName) {
      return null;
    }

    const images = cameraImages[camera.id] || [];
    
    // Skip cameras with no images
    if (images.length === 0) {
      return null;
    }
    
    // Check if the latest image is too old
    const latestImage = images[0];
    if (latestImage && isImageTooOld(latestImage.date)) {
      return null; // Otomatik olarak eski görüntüleri gizle
    }
    
    const isOutdated = latestImage && isImageOutdated(latestImage.date);

    return (
      <div key={camera.id} className="mb-12">
        <div className={`rounded-lg shadow-md p-6 transition-colors duration-300 ${
          isOutdated 
            ? 'bg-red-50 border-2 border-red-200' 
            : 'bg-green-50'
        }`}>
          <div className="mb-4">
            <h2 className={`text-2xl font-bold mb-2 ${
              isOutdated ? 'text-red-800' : 'text-green-800'
            }`}>{camera.name}</h2>
            <p className={`text-sm ${isOutdated ? 'text-red-700' : 'text-green-700'}`}>
              Seri No: {camera.serialNo}
            </p>
            <p className={`text-sm ${isOutdated ? 'text-red-700' : 'text-green-700'}`}>
              Pot ID: {camera.potId}
            </p>
            {isOutdated && (
              <p className="mt-2 text-red-600 text-sm font-medium">
                Son görüntü tarihi: {formatDate(latestImage.date)} ({getImageAge(latestImage.date)})
              </p>
            )}
          </div>
          
          <div className="mt-4">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
              {images.map((base64Image, index) => (
                <div 
                  key={index} 
                  className={`rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-lg cursor-pointer ${
                    isOutdated ? 'bg-red-50' : 'bg-green-50'
                  }`}
                  onClick={() => handleImageClick(base64Image)}
                >
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      src={`data:image/jpeg;base64,${base64Image.base64Image}`}
                      alt={`${camera.name} Görüntü ${index + 1}`}
                      className={`w-full h-full object-cover ${
                        isOutdated ? 'opacity-90' : 'opacity-100'
                      }`}
                      onError={(e) => {
                        console.error('Görüntü yüklenemedi:', base64Image);
                        e.target.src = 'placeholder.jpg';
                      }}
                    />
                  </div>
                  <div className={`p-2 ${isOutdated ? 'bg-red-50' : 'bg-green-50'}`}>
                    <p className={`text-xs text-center ${
                      isOutdated ? 'text-red-700' : 'text-green-700'
                    }`}>
                      {formatDate(base64Image.date)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-5xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-8 text-green-800">
          Kamera Görüntüleri
        </h1>
        
        <div className="mb-8 bg-white rounded-lg shadow-md p-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            <div className="flex items-center">
              <Filter size={20} className="text-green-600 mr-2" />
              <span className="text-green-800 font-medium">Filtrele:</span>
            </div>
            
            <div className="flex-grow max-w-md">
              <select
                value={selectedCameraName}
                onChange={handleFilterChange}
                className="w-full p-2 border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <option value="">Tüm Kameralar</option>
                {uniqueCameraNames.map((name, index) => (
                  <option key={index} value={name}>{name}</option>
                ))}
              </select>
            </div>
            
            <button 
              onClick={fetchCameras}
              className="bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300"
            >
              Fotoğrafları Güncelle
            </button>
          </div>
        </div>
        
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="spinner"></div>
            <p className="ml-4 text-lg text-green-600">Yükleniyor...</p>
          </div>
        ) : (
          cameras.map(camera => renderCameraSection(camera))
        )}
      </div>

      <ImageModal
        isOpen={modalImage !== null}
        onClose={() => setModalImage(null)}
        imageUrl={modalImage ? `data:image/jpeg;base64,${modalImage.base64Image}` : ''}
        date={modalImage?.date}
        formatDate={formatDate}
      />
    </div>
  );
};

export default PhotoPage;