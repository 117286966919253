import React from 'react';
import { useTranslation } from 'react-i18next';

function LivePage() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">{t('liveStream')}</h1>
      <div className="aspect-video w-full">
        <iframe
          src="https://www.youtube.com/embed/live_stream?channel=UCUfHnBR4Y2KrChHKspuruVQ"
          title="YouTube live stream"
          frameBorder="0"
          allowFullScreen
          className="w-full h-full"
        ></iframe>
      </div>
    </div>
  );
}

export default LivePage;
